.container-wrapper {
  width: 80%;
  margin-right: 4%;
}

.utable-wrapper {
  width: 100%;
}

.filterIcon-wrapper {
  margin-left: 10px;
  min-width: 34px;
}

.utable {
  display: block;
  overflow: hidden;
  table-layout: fixed;
  border-collapse: collapse;
  box-shadow: 0px 10px 10px #ccc;
  border-radius: 10px;
  white-space: nowrap;
  width: 100em;
  max-width: 90%;
  margin: auto;
  table-layout: auto;
  overflow-x: auto;
}

.utable thead {
  background-color: #184437;
  color: #fff;
  align-items: center;
}

.utable th {
  padding: 0.8rem;
  border: 1.5px solid #dededf;
  font-weight: bold;
}

.utable td {
  border-top: 0.5px solid #ddd;
  border: 1.5px solid #dededf;
  overflow: hidden;
  text-overflow: ellipsis;
}

.utable tbody tr td {
  font-size: 14px;
  padding: 10px;
}

.uexpand {
  width: 100%;
}

.ulabel {
  border-radius: 10px;
  padding: 0.3rem;
  color: white;
}

.ulabel-Active {
  background-color: #42a942;
}

.ulabel-InActive {
  background-color: #e10d05;
}

.uactions {
  display: flex;
  justify-content: space-around;
}

.udelete-btn {
  color: #e10d05;
}

.ubutton {
  background-color: rgb(24, 68, 55) !important;
  height: 45px;
  width: 120px;
  border-radius: 6px !important;
  color: #fff !important;
  margin-left: 2%;
  margin-top: 12px;
  align-items: "center";
  outline-color: #184437 !important;
}

.uform-select {
  background-color: var(--white);
  border: 1px solid;
  border-color: "#184437";
  border-radius: 5px;
  padding: 16px 12px;
}

.uabutton {
  background-color: rgb(24, 68, 55) !important;
  height: 40px;
  width: 140px;
  border-radius: 6px !important;
  color: #fff !important;
  margin-left: 2%;
  margin-top: 12px;
  align-items: "center";
  outline-color: #184437 !important;
  padding-left: 0px;
  padding-right: 0px;
}

.uinput {
  margin-top: 20px;
  margin-left: 10px;
}
