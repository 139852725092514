.container-wrapper {
  width: 80%;
  margin-right: 4%;
}

.filterIcon-wrapper {
  margin-left: 10px;
  min-width: 34px;
}

.ctable-wrapper {
  width: 100%;
}

.ctable {
  display: block;
  overflow: hidden;
  table-layout: fixed;
  border-collapse: collapse;
  box-shadow: 0px 10px 10px #ccc;
  border-radius: 10px;
  white-space: nowrap;
  width: 100em;
  max-width: 90%;
  margin: auto;
  table-layout: auto;
  overflow-x: auto;
}

.ctable thead {
  background-color: #184437;
  color: #fff;
  align-items: center;
}

.ctable th {
  padding: 0.8rem !important;
  border: 1.5px solid #dededf;
  font-weight: bold;
}

.ctable td {
  border-top: 0.5px solid #ddd;
  border: 1.5px solid #dededf;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.8rem !important;
}

.capitalizeText {
  text-transform: capitalize;
}

.ctable tbody tr td {
  font-size: 14px;
}

.cexpand {
  width: 100%;
}

.clabel {
  border-radius: 10px;
  padding: 0.5rem !important;
  color: white;
}

.clabel-Active {
  background-color: #42a942 !important;
}

.clabel-InActive {
  background-color: #e10d05 !important;
}

.clabel-Done {
  background-color: #42a942 !important;
}

.clabel-None {
  background-color: "none" !important;
}

.cactions {
  display: flex;
  justify-content: space-around;
}

.cbutton {
  background-color: rgb(24, 68, 55) !important;
  height: 45px;
  width: 120px;
  border-radius: 6px !important;
  color: #fff !important;
  margin-left: 10px;
  margin-top: 8px;
  align-items: "center";
  outline-color: #184437 !important;
}

.cinput {
  margin-top: 20px;
  margin-left: 10px;
}
