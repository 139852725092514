.mtable-wrapper {
  width: 100%;
}

.mtable {
  display: block;
  overflow: hidden;
  table-layout: fixed;
  border-collapse: collapse;
  box-shadow: 0px 10px 10px #ccc;
  border-radius: 10px;
  white-space: nowrap;
  width: 100em;
  max-width: 90%;
  margin: auto;
  table-layout: auto;
  overflow-x: auto;
}

.mtable thead {
  background-color: #184437;
  color: #fff;
  align-items: center;
}

.mtable th {
  padding: 0.8rem;
  border: 1.5px solid #dededf;
  font-weight: bold;
}

.mtable td {
  border-top: 0.5px solid #ddd;
  border: 1.5px solid #dededf;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mtable tbody tr td {
  font-size: 14px;
  padding: 10px;
}

.mactions {
  display: flex;
  justify-content: space-around;
}
