.container-wrapper {
  width: 80%;
  margin-right: 4%;
}

.ultable-wrapper {
  width: 100%;
}

.ultable {
  display: block;
  overflow: hidden;
  table-layout: fixed;
  border-collapse: collapse;
  box-shadow: 0px 10px 10px #ccc;
  border-radius: 10px;
  white-space: nowrap;
  width: 100em;
  max-width: 90%;
  margin: auto;
  table-layout: auto;
  overflow-x: auto;
}

.ultable thead {
  background-color: #184437;
  color: #fff;
  align-items: center;
}

.ultable th {
  padding: 0.8rem;
  border: 1.5px solid #dededf;
  font-weight: bold;
}

.ultable td {
  height: 2px;
  color: "red";
  border-top: 0.5px solid #ddd;
  border: 1.5px solid #dededf;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ulexpand {
  width: 100%;
}

.ulactions {
  display: flex;
  justify-content: space-around;
}

.ulactions svg {
  cursor: pointer;
}

.ulbutton{
  background-color: rgb(24, 68, 55) !important;
  height: 45px;
  width: 120px;
  border-radius: 6px !important;
  color: #fff !important;
  margin-left: 2%;
  margin-top: 12px;
  align-items:"center";
  outline-color: #184437 !important;
}

.ulinput{
  margin-top: 20px;
  margin-left: 10px;
}