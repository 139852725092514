.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  background: #f3f1f2;
  font-family: sans-serif;
}

.app {
  margin: 1rem 4rem;
}

.app .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.app .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.app .ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.app .ql-editor {
  min-height: 18em;
}

.input {
  margin-top: 20px;
}
.btnr {
  position: absolute;
  right: 9.5%;
  top: 14%;
}

.button {
  background-color: rgb(24, 68, 55) !important;
  height: 40px;
  width: 120px;
  border-radius: 6px !important;
  color: #fff !important;
  margin-left: 45%;
  outline-color: #184437 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #184437 !important;
}

.dialog {
  width: 100%;
}
