.admin-pagination {
  position: unset;
  justify-content: flex-end;
  max-width: 90%;
  margin: 20px auto 0px;
  padding-bottom: 20px;
}

.admin-pagination .admin-page-item .admin-page-link {
  font-size: 0.9rem;
  font-weight: bold;
  color: #212529;
  background-color: transparent;
  border: 0;
  outline: 0;
  transition: all 0.3s linear;
}
/*
New styles */
.admin-pagination .admin-page-item.active .admin-page-link {
  color: #fff;
  background-color: #184437 !important;
  border-radius: 0.125rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.2s linear;
}
