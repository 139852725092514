.container-wrapper {
  width: 80%;
  margin-right: 4%;
}

.filterIcon-wrapper {
  margin-left: 10px;
  min-width: 34px;
}

.ftable-wrapper {
  width: 100%;
}

.ftable {
  display: block;
  overflow: hidden;
  table-layout: fixed;
  border-collapse: collapse;
  box-shadow: 0px 10px 10px #ccc;
  border-radius: 10px;
  white-space: nowrap;
  width: 100em;
  max-width: 90%;
  margin: auto;
  table-layout: auto;
  overflow-x: auto;
}

.ftable thead {
  background-color: #184437;
  color: #fff;
  align-items: center;
}

.ftable th {
  padding: 0.8rem;
  border: 1.5px solid #dededf;
  font-weight: bold;
}

.ftable td {
  height: 2px;
  color: "red";
  border-top: 0.5px solid #ddd;
  border: 1.5px solid #dededf;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-cell {
  font-size: 14px;
  padding: 10px;
}

.fexpand {
  width: 100%;
}

.flabel {
  border-radius: 10px;
  padding: 0.5rem;
  color: white;
}

.flabel-draft {
  background-color: #777;
}

.flabel-Active {
  background-color: #42a942;
}

.flabel-Pending {
  background-color: #e8c022;
}

.factions {
  display: flex;
  justify-content: space-around;
}

.fdelete-btn {
  color: #e10d05;
}

.fbutton {
  background-color: rgb(24, 68, 55) !important;
  height: 45px;
  width: 120px;
  border-radius: 6px !important;
  color: #fff !important;
  margin-left: 10px;
  margin-top: 8px;
  align-items: "center";
  outline-color: #184437 !important;
}

.fresetbutton {
  background-color: rgb(24, 68, 55) !important;
  height: 45px;
  width: 118px;
  border-radius: 6px !important;
  color: #fff !important;
  margin-left: 10px;
  margin-top: 8px;
  align-items: "center";
  outline-color: #184437 !important;
}

.finput {
  margin-top: 20px;
  margin-left: 10px;
}

.fform-select {
  background-color: var(--white);
  border: 1px solid;
  border-color: "#184437";
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 10px;
}

.aform-select {
  background-color: var(--white);
  border: 1px solid;
  border-color: "#184437";
  border-radius: 5px;
  margin-top: 10px;
  padding: 14px 10px;
}
