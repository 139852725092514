.vstable-wrapper {
  width: 100%;
}

.vstable {
  display: block;
  overflow: hidden;
  table-layout: fixed;
  border-collapse: collapse;
  box-shadow: 0px 10px 10px #ccc;
  border-radius: 10px;
  white-space: nowrap;
  width: 50em;
  max-width: 90%;
  margin: auto;
  table-layout: auto;
  overflow-x: auto;
}

.vstable thead {
  background-color: #184437;
  color: #fff;
  align-items: center;
}

.vstable th {
  padding: 0.8rem !important;
  border: 1.5px solid #dededf;
  font-weight: bold;
}

.vstable td {
  border-top: 0.5px solid #ddd;
  border: 1.5px solid #dededf;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.8rem !important;
}

.vstable tbody tr td {
  font-size: 14px;
  padding: 10px;
}

.vsexpand {
  width: 100%;
}

.vsactions {
  display: flex;
  justify-content: space-around;
}

.vsdelete-btn {
  color: #e10d05 !important;
}